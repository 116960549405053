import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { bscTestnet } from 'viem/chains'
import { watchAccount, watchNetwork } from '@wagmi/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WagmiConfigService {
  public supportedChains: any = environment.SUPPORTED_CHAINS;
  private accountSubject = new BehaviorSubject<any>(undefined);
  account$ = this.accountSubject.asObservable();
  private networkSubject = new BehaviorSubject<any>(undefined);
  network$ = this.networkSubject.asObservable();
  connectorInfo: any;

  constructor() { }

  public wagmiConfiguration() {
    const chains = this.supportedChains;
    const projectId = environment.WALLET_CONNECT_PROJECT_ID;

    // Create a metadata object
    const metadata = {
      name: 'AOC',
      description: 'USDT Over Liquidity Contribution',
      url: 'https://web3.alphaomegacoin.com', // origin must match your domain & subdomain
      icons: ['https://avatars.githubusercontent.com/u/37784886']
    }

    const config = defaultWagmiConfig({ chains, projectId, metadata })

    const modal = createWeb3Modal({
      wagmiConfig: config,
      projectId,
      themeMode: 'dark',
      enableAnalytics: true // Optional - defaults to your Cloud configuration
    })

    watchAccount(async (account) => {
      // console.log('watch account', account);
      // if (account.isConnected) {
      setTimeout(() => {
        const wagmiStore: any = localStorage.getItem('wagmi.store');
        const wagmiAccount = JSON.parse(wagmiStore);
        this.connectorInfo = wagmiAccount?.state?.data;
        // console.log('wagmi store data', this.connectorInfo)
        if (this.connectorInfo.account !== undefined) {
          this.accountSubject.next(this.connectorInfo); // Emit the account data
        } else {
          // disconnect from wallet need to handle
          // const userId = localStorage.getItem('userId');
          // if (userId && !!this.connectorInfo.account !== undefined) {
          //    window.location.href = '/';
          // }
        }
      }, 500);
      // }
    });

    watchNetwork(async (network) => {
      // console.log('network watch', network);
      this.networkSubject.next(network); // Emit the account data
      // if()
      // window.location.reload();
      // if (network?.chain?.unsupported) {
      // }
      //   // window.location.reload();
      //   this.openModalBox('exampleModal');
      //   this.toast.error('Please connect to BSC Mainnet');
      //   isModalOpen = true;
      // } else {
      //   if (!network?.chain?.unsupported && isModalOpen) {
      //     this.closeModalBox('exampleModal');
      //     isModalOpen = false;
      //     window.location.reload();
      //   }
      // }
    });
  }
}
