import { RouterOutlet } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";
import { WarningModalComponent } from './shared/component/warning-modal/warning-modal.component';
import { BsModalRef, BsModalService, ModalDirective, ModalModule, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { WagmiConfigService } from './shared/services/wagmi-config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [RouterOutlet, NgIf,CommonModule, NgxSpinnerModule, WarningModalComponent,ModalModule,NgIf, TranslateModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppComponent implements OnInit {
  title = 'aoc-website';
  constructor(private wagmiConfigService: WagmiConfigService
  ){
  }
  ngOnInit(){
    this.wagmiConfigService.wagmiConfiguration();
  }
}
