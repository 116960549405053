import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../shared/services/local-storage.service';


@Injectable({
    providedIn: 'root',
  })
  export class AuthGuard implements CanActivate {
    constructor(private router:Router, private localStorageService: LocalStorageService){}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree {
      const token = this.localStorageService.getToken();
      const wagmiStore: any = localStorage.getItem('wagmi.store');
      const account = JSON.parse(wagmiStore);

      if (token && !!account?.state?.data?.account) {
        return true;
      } else {
       this.router.navigate(['/']);
        return false;
      }
    }
  }
