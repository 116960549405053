import { bscTestnet, bsc } from "viem/chains";

export const environment = {
    production: false,
    SUPPORTED_CHAINS: [bscTestnet],
    ENCRYPT_LOCAL_STORAGE: false,
    LANGUAGES_FILE_PATH: 'assets/i18n/',
    LOCAL_STORAGE_SECRET: 'D96Q2M84E3400063E8366912AQ45488H',
    WALLET_CONNECT_PROJECT_ID: '1022f8e674ac7cca903dd5d2df11cf54',
    API_BASE_URL: 'https://aoc-backend.seaswap.co/',
    dev_url:'https://aoc-user.seaswap.co/',
    COINBIT_API_BASE_URL: 'https://api.coinsbit.io/api/v1',
    NETWORK_ID:['0x61'],
    COUNTRY_CODE_BASE_URL: 'https://ipapi.co/json/',
    BSC_TRANSACTION_HASH_BASEURL: 'https://bscscan.com/tx/',
    TRON_TRANSACTION_HASH_BASEURL: 'https://tronscan.org/#/transaction/',
    ETH_TRANSACTION_HASH_BASEURL: 'https://etherscan.io/tx/',
    DISTRIBUTION_DAPP_URL: 'https://devtokendistribution.alphaomegacoin.com/',

    // CONTRACT_ADDRESS:{
    //     '0x38': {
    //         NETWORK_NAME:'BSC MAINNET',
    //         NETWORK_SYMBOL:'bsc',
    //         NETWORK_CURRENCY:'BNB',
    //         TRANSACTION_URI: 'https://bscscan.com/tx/',
    //         TEST_CHAIN: 'bsc',
    //         RPC_URL: "https://eth-mainnet.g.alchemy.com/v2/Ehx84XSejJSJRNvl0EqCMokHaTmEEKlP",
    //         AOC_TOKEN : "0x8BE3f52CA83E7C2aEf7C33dcE7fAB0106CDfa829",
    //         CROWD_SALE:"0x7de3630d8C04Fc425a07CAe81080923d60cDA2d8",
    //         VESTING_CONTRACT:"0x19F36bBe410C6BC2c263146073F9396F2e5ac091",
    //         USDT_CONTRACT:"0x55d398326f99059ff775485246999027b3197955",
    //         CROWD_SALE_V2:"0x9645a07Bfee003061bcf8090529ba72c44D42058"
    //         },
    //     '0x61': {
            NETWORK_NAME:'BSC-TESTNET',
            NETWORK_SYMBOL:'bsc',
            NETWORK_CURRENCY:'BNB',
            TRANSACTION_URI: 'https://testnet.bscscan.com/tx/',
            TEST_CHAIN: 'bsc',
            RPC_URL: "https://bnb-testnet.g.alchemy.com/v2/zmULGSf_SNsscL74s_AeyHlPgn2XsdmV",
            AOC_TOKEN : "0x8BE3f52CA83E7C2aEf7C33dcE7fAB0106CDfa829",
            CROWD_SALE:"0x7de3630d8C04Fc425a07CAe81080923d60cDA2d8",
            VESTING_CONTRACT:"0x19F36bBe410C6BC2c263146073F9396F2e5ac091",
            USDT_CONTRACT:"0x52D896ff34D797efe460dB898DDa1edE0bfe4a9b",
            CROWD_SALE_V2:"0xab6FafcA96b9F1384413FD0008169461A8Da24d4",
            CHAIN_ID:97,
    //       },
    // },

    ALLOWED_NETWORKS: [
        '0x61', // BSC Testnet
        '0x38',
        '0x5', // ETH Goerli Testnet
        '0x13881', // Polygon Mumbai Testnet
        '0xa869', // Avalanche Fuji Testnet
        '0xfa2', // Fantom Testnet
        '0x152', // Cronos Testnet
        '0x507', // Moonbase Alpha Testnet
        '0x1', // ETH Mainnet
        '0x38', // BSC Mainnet
        '0x89', // Polygon Mainnet
        '0xa86a', // Avalanche C-Chain Mainnet
        '0xfa', // Fantom Opera Mainnet
        '0x19', // Cronos Mainnet
        '0x504', // Moonbeam Mainnet
        ],
    EN_WHATSAPP_LINK:"https://whatsapp.com/channel/0029Vb5qc5JGk1FpNl8qy30g",
    FR_WHATSAPP_LINK:"https://whatsapp.com/channel/0029VaGARw98PgsOIqrx9Q3J"
};
