import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ensureSeconds',
  standalone: true
})
export class EnsureSecondsPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;
    
    // Check if seconds are missing (length is 16, which indicates "YYYY-MM-DDTHH:MM")
    if (value.length === 16) {
      return value + ':00';  // Add ":00" seconds to the time
    }
    
    return value; 
  }

}