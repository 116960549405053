
<router-outlet></router-outlet>
<ngx-spinner
size = "medium"
type = "square-jelly-box">
<p style="color: white" > {{'LOADING' | translate}}... </p></ngx-spinner>

<ngx-spinner name="sp2" type="ball-fussion" size="medium">
    <p style="color: white" > newww... </p>
</ngx-spinner>
