import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matrixPeriodFormatPipe',
  standalone: true,
})
export class MatrixPeriodFormatPipePipe implements PipeTransform {

  transform(value: string): string {
    let lang = localStorage.getItem('lang');
    let isEnglish = lang == '"en"';
    switch (value) {
      case 'may18Aug28':        
        return isEnglish ? 'May 18,2024 - Aug 28,2024' : '18 mai,2024 - 28 août,2024';
      case 'aug29Jan25':      
        return isEnglish ? 'Aug 29,2024 - Jan 25,2025' : '29 août,2024 - 25 jan,2025';
      case 'jan26Feb20':
        return isEnglish ? 'Jan 26,2025 - Feb 20,2025' : '26 jan,2025 - 20 Févr,2025';
      case 'feb21Mar5':
        return isEnglish ? 'Feb 21,2025 - Mar 5,2025' : '21 févr,2025 - 5 mars,2025';
      case 'afterMar5':
        return isEnglish ? 'After Mar 5,2025' : 'Après le 05 mars 2025';
      default:
        return value || '-';
    }
  }
}
