import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.gaurd';
import { OurTeamComponent } from './home/our-team/our-team.component';

export const routes: Routes = [
  { path:'', loadComponent: () => import('../app/home/home.component').then(c => c.HomeComponent)},
  { path: 'wallet-connect', loadComponent: () => import('../app/dashbaord/wallet-connect/wallet-connect.component').then(c => c.WalletConnectComponent)},
  { path: 'signup', loadComponent: () => import('../app/dashbaord/signup/signup.component').then(c => c.SignupComponent)},
  { path: 'user-dashboard', canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/dashboard/dashboard.component').then(c => c.DashboardComponent) },
  { path: 'buynow',  canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/buynow/buynow.component').then(c => c.BuynowComponent)},
  { path: 'user-profile', canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/profile/profile.component').then(c => c.ProfileComponent)},
  { path: 'my-referral', canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/profile/myrefferal/myrefferal.component').then(c => c.MyrefferalComponent)},
  { path:'our-team', loadComponent: () => import('../app/home/our-team/our-team.component').then(c => c.OurTeamComponent)},
  { path:'top-sponsors-list', canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/leaderboard/leaderboard.component').then(c => c.LeaderboardComponent)},
  { path:'top-sponsors', loadComponent: () => import('../app/dashbaord/header-leaderboard/header-leaderboard.component').then(c => c.HeaderLeaderboardComponent)},
  // { path:'top-countries-list', loadComponent: () => import('../app/dashbaord/top-countries/top-countries.component').then(c => c.TopCountriesComponent)},
  // { path:'top-countries', loadComponent: () => import('../app/dashbaord/header-top-country/header-top-country.component').then(c => c.HeaderTopCountryComponent)},
  { path: 'privacy-policy', loadComponent: () => import('../app/dashbaord/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent)},
  // {path:'miracle-matrix', canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/miracle-matrix/miracle-matrix.component').then(c => c.MiracleMatrixComponent)},
  {path:'collaborative-contribution', canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/collaborative-contribution/collaborative-contribution.component').then(c => c.CollaborativeContributionComponent)},
  {path:'collaboration-form', canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/collaboration-form/collaboration-form.component').then(c => c.CollaborationFormComponent)},
  {path:'flash-operation',canActivate: [AuthGuard], loadComponent: () => import('../app/dashbaord/flash-operation/flash-operation.component').then(c => c.FlashOperationComponent)}
];

